import React from 'react'
import { Router } from '@reach/router'

import { AdminRoute, ProtectedRoute } from 'gatsby-components'

export default function Admin() {
  return (
    <Router basepath="/admin">
      <ProtectedRoute allowedRole="org-admin" component={AdminRoute} path="*" />
    </Router>
  )
}
